<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<CartelSetting
			:user="user"

			class="flex-side mr-30"
		></CartelSetting>

		<div class="flex-1 bg-white pa-20 radius-20">

			<div class="under-line-dick pb-10 flex-row">
				<h3 class="flex-1">멤버 등급 관리</h3>
			</div>
			<!-- 커버 -->
			<div class="mt-20">
				<div>
					<h5>등급명</h5>
					<div class="mt-20">
						<input
							v-model="items_grade[0].cartl_member_grade_name"
							placeholder="관리자"
							class="box radius-20 pa-10-20 mr-10"
							/>
						<span>카르텔을 관리하는 최고 관리자입니다.</span>
					</div>
					<div class="mt-20">
						<input
							v-model="items_grade[1].cartl_member_grade_name"
							placeholder="부관리자"
							class="box radius-20 pa-10-20 mr-10"
						/>
						<span>카르텔을 관리하는 스텝입니다</span>
					</div>
				</div>

				<hr class="under-line mt-20">

				<div class="mt-20">
					<div>점수는 방문수(1점) + 게시글(3점)의 합산입니다.</div>
					<ul class="">
						<li
							class="mt-20"
						>
							<input
								v-model="items_grade[2].cartl_member_grade_name"
								placeholder="LV1"
								class="box radius-20 pa-10-20 mr-10"
							/>
							<span>가입 직후 활동을 하는 멤버입니다.</span>
						</li>
						<li
							v-for="(grade, g_index) in member_grade_list"
							:key="'grade_' + g_index"
							class="mt-20"
						>
							<input
								v-model="grade.cartl_member_grade_name"
								:placeholder="'LV' + (g_index + 2)"
								class="box radius-20 pa-10-20 mr-10"
							/>

							<span class="dot inline-block mr-10"></span>
							<span class="mr-10">NFT 보유수량</span>
							<input
								v-model="grade.nft_holding_quantity"
								placeholder="보유수량"
								class="box radius-20 pa-10-20 mr-5 width-15 "
							/>
							<span class="mr-10">개</span>

							<span class="dot inline-block mr-10"></span>

							<span class="mr-10">점수</span>
							<input
								v-model="grade.score"
								placeholder="점수"
								class="box radius-20 pa-10-20 mr-5 width-15 "
							/>
							<span>점</span>

							<button
								class="btn-inline btn-primary-outline radius-20 float-right"
								@click="removeGrade(grade, g_index)"
							>삭제</button>
						</li>
					</ul>
				</div>

				<hr class="under-line mt-20">

				<div class="mt-20">
					<div class="flex-row justify-space-between">
						<button
							class="btn-inline btn-primary-outline radius-20"
							@click="addGrade"
						><v-icon class="color-primary size-px-20">mdi-plus</v-icon> 멤버 등급 추가</button>
						<button
							class="btn-inline btn-primary radius-20"
							@click="postGrade"
						>저장</button>
					</div>
				</div>
			</div>
		</div>

		<PopupConfirm
			v-if="is_on_delete"

			@click="postGradeDelete"
			@cancel="is_on_delete = false"
		>
			<template
				v-slot:title
			>멤버 등급 삭제</template>
			<template
				v-slot:main-txt
			>해당 멤버등급을 삭제하시겠습니까?</template>
			<template
				v-slot:sub-txt
			>삭제하시려면 확인을 클릭해주세요</template>
		</PopupConfirm>
	</div>
</template>

<script>
	import CartelSetting from "@/view/Cartel/CartelSetting";
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'mafia0841'
		,
		components: {PopupConfirm, CartelSetting},
		props: ['user']
		, data: function(){
			return {
				program: {
					name: 'member grade'
					, title: '멤버 등급 관리'
					, not_footer: true
					, bg_contents: 'bg-gray01'
				}
				, items_grade: [
					{ cartl_member_grade_name: '', nft_holding_quantity: 0, score: 0}
					, { cartl_member_grade_name: '', nft_holding_quantity: 0, score: 0}
					, { cartl_member_grade_name: '', nft_holding_quantity: 0, score: 0}
				]
				, item_grade_info: {

				}
				, is_on_delete: false
				, item_grade_delete: {}
			}
		}
		, computed: {

			member_grade_list: function(){
				let t = []

				this.items_grade.filter((item, index) => {
					if(index > 2){
						t.push(item)
					}
				})

				return t
			}
			, request_items_grade: function(){
				return this.items_grade.filter((item) => {
					item.nft_holding_quantity = Number(item.nft_holding_quantity)
					item.score = Number(item.score)

					return item
				})
			}
		}
		, methods: {

			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_member_grade_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.items_grade = result.data.cartl_member_grade_list
						this.item_grade_info = result.data.resultinfo
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, addGrade: function(){
				this.items_grade.push({ cartl_member_grade_name: '', use_fg: 'Y', base_authority_fg: 'N', cartl_member_div_code: 'CA02500003'})
			}
			, removeGrade: function(grade, index){
				let d_index = index + 3
				if(this.items_grade.length <= 1){
					this.$bus.$emit('notify', { type: 'error', message: '더 이상 삭제할 수 없습니다.'})
					return false
				}else{

					console.log('this.items_grade', this.items_grade)
					if(!grade.cartl_member_grade_number){
						this.items_grade.splice(d_index, 1)
					}else{
						this.is_on_delete = true
						this.item_grade_delete = grade
					}
					console.log('this.items_grade', d_index, this.items_grade)
				}
			}
			, postGrade: async  function(){
				if(this.checkGrade()){
					try{
						this.$bus.$emit('on', true)
						const result = await this.$request.init({
							method: 'post'
							, url: this.$api_url.api_path.post_cartel_member_grade
							, data: {
								member_number: this.user.member_number
								, cartl_number: this.$route.params.idx
								// , cartl_member_div_code: 'CA02500003'
								, cartl_member_grade_save_list: this.request_items_grade
							}
							, type: true
						})

						if(result.success){
							await this.getData()
						}else{
							throw result.message
						}

					}catch (e) {
						console.log(e)
						this.$bus.$emit('notify', { type: 'error', message: e})
					}finally {
						this.$bus.$emit('on', false)
					}
				}
			}
			, checkGrade: function(){
				let t = false
				let d = {}
				try{
					this.items_grade.filter((item, index) => {
						if(index > 2){

							if(!item.cartl_member_grade_name){
								throw '등급명을 입력하세요'
							}else if(!(item.nft_holding_quantity + '')){
								throw 'NFT 보유수량을 입력하세요'
							}else if(!(item.score + '')){
								throw '점수를 입력하세요'
							}

							if(item.nft_holding_quantity > d.nft_holding_quantity || item.score > d.score) {
								t = true
							}else{
								throw '상위 등급은 하위등급보다 NFT 보유수량 또는 점수가 높아야 합니다.'
							}
						}else if(index > 1){

							if(!item.cartl_member_grade_name){
								throw '등급명을 입력하세요'
							}
						}else if(index > 0){
							if(!item.cartl_member_grade_name){
								throw '카르텔 운영자명을 입력하세요'
							}
						}else{
							if(!item.cartl_member_grade_name){
								throw '카르텔 개설자명을 입력하세요'
							}
						}

						d = item
						t = true
					})

					return t
				}catch(e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
					t = false
					return t
				}
			}
			, postGradeDelete: async function(){

				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_member_grade_delete
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_member_div_code: this.item_grade_delete.cartl_member_div_code
							, cartl_member_grade_number: this.item_grade_delete.cartl_member_grade_number
							, use_fg: 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_on_delete = false
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>

<style>
.dot{
	background-color: #dadada;
	border-radius: 50px;
	width: 2px; height: 2px; padding: 2px;
}
</style>